export const Example_data = [
  // {
  //   name: "T1 Vision",
  //   cover: "/t1cover.jpg",
  //   logo: "/t1logo.png",
  //   images: ["/t3.jpg", "/t2.jpg", "/t1.jpg"],
  //   desc: "t1-desc",
  // },
  {
    name: "Urameta",
    cover: "/urametacover.png",
    images: ["/urameta.png", "/urameta2.png"],
    logo: "/urametalogo.png",
    desc: "urameta-desc",
  },
  // {
  //   name: "drgold",
  //   cover: "/drgoldcover.png",
  //   images: ["/drgold (2).jpg", "/drgold (1).jpg" , "/drgold (3).jpg"],
  //   logo: "/drgoldlogo.png",
  //   desc: "drgold-desc",
  // },
  // {
  //   name: "sarapasha",
  //   cover: "/sarapashacover.jpeg",
  //   images: ["/sarapasha1.png", "/sarapasha2.png", "/sarapasha3.png"],
  //   logo: "/sarapasha-logo.png",
  //   desc: "sarapasha-desc",
  // },
  // {
  //   name: "iaclub",
  //   cover: "/iaclobcover.png",
  //   images: [
  //     "/iaclub1.png",
  //     "/iaclub2.png",
  //     "/iaclub3.png",
  //     "/iaclub4.png",
  //     "/iaclub5.png",
  //   ],
  //   logo: "/iaclublogo.png",
  //   desc: "iaclub-desc",
  // },
  // {
  //   name: "shame",
  //   cover: "/shamecover.png",
  //   images: ["/shame.png", "/shame2.png", "/shame3.png", "/shame4.png"],
  //   logo: "/shamlogo.png",
  //   desc: "shame-desc",
  // },
];
